




















































































































































































































































































































import Vue from "vue";
import { SidebarLinks } from "@/interfaces/candidate/nav_side_bar";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_LATEST_WORK_HISTORY,
  GET_USER_DETAILS,
  LOGOUT_USER
} from "@/store/modules/auth/constants";
import { clean_string, get_name_initials } from "@/utils/global";
import {
  GET_ROLE_BY_NAME,
  INIT_NOTIFICATIONS,
  NOTIFICATION_ACTION,
  NOTIFICATION_ACTION_LOADING,
  NOTIFICATIONS,
  NOTIFICATIONS_COUNT,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  NotificationEventDetails,
  NotificationsResponse
} from "@/interfaces/responses/notifications";
import {
  UpdateNotificationStatusPayload,
  SiteDirections
} from "@/store/modules/common/interfaces";
// import AppPoweredBy from "@/components/shared/AppPoweredBy.vue";

export default Vue.extend({
  name: "NavSideBar",
  components: { DataNotFound, ChangeLanguage },
  data() {
    return {
      notification_overlay: false,
      module: "",
      drawer: true,
      hcms_logo: require("@/assets/logos/qudraTechBlack.svg"),
      inception_logo: require("@/assets/logos/inception.svg"),
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      notification_icon: require("@/assets/icons/linear/notification.svg"),
      active_route_icon: require("@/assets/icons/linear/speed-test.svg"),
      sidebar_links: [] as SidebarLinks[]
    };
  },
  watch: {
    get_site_direction() {
      this.set_sidebar();
    },
    "$vuetify.breakpoint.mdAndDown"(isSmallScreen) {
      if (isSmallScreen) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    }
  },
  created() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.drawer = false;
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("auth", {
      user: GET_USER_DETAILS,
      get_latest_work_history: GET_LATEST_WORK_HISTORY,
      auth_loading: AUTH_LOADING
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME,
      get_notifications_count: NOTIFICATIONS_COUNT,
      get_notifications: NOTIFICATIONS,
      notification_action_loading: NOTIFICATION_ACTION_LOADING,
      get_site_direction: SITE_DIRECTION
    }),
    if_assessment() {
      const route_name = clean_string(this.$route.name ?? "");
      return (
        route_name === "candidate-assessment" ||
        route_name === "inception-anotation-assessment" ||
        route_name === "inception-english-assessment" ||
        route_name === "english-assessment" ||
        route_name === "assessment-report" ||
        route_name === "assessment-completed" ||
        route_name === "annotation-assessment"
      );
    }
  },
  async mounted() {
    this.set_sidebar();
    if (this.get_role_by_name("recruiter").id === this.user.role_id) {
      this.module = "recruiter";
    } else if (
      this.get_role_by_name("recruiter admin").id === this.user.role_id
    ) {
      this.module = "recruiter_admin";
    } else this.module = "candidate";
    await this.init_notifications();
  },
  methods: {
    get_name_initials,
    ...mapActions("auth", {
      logout_user: LOGOUT_USER
    }),
    async _logout_user() {
      await this.logout_user();
      await this.$router.push("/auth/login");
    },
    ...mapActions("common", {
      init_notifications: INIT_NOTIFICATIONS,
      notification_action: NOTIFICATION_ACTION
    }),
    async mark_all_as_read() {
      const ids = this.get_notifications.map(
        (notification: NotificationsResponse) => notification.id
      );
      const payload: UpdateNotificationStatusPayload = {
        notificationIds: ids
      };
      await this.notification_action(payload);
    },
    async dismiss_notification(notification: NotificationsResponse) {
      const payload: UpdateNotificationStatusPayload = {
        notificationIds: [notification.id]
      };
      await this.notification_action(payload);
    },
    async notification_navigate(notification: NotificationsResponse) {
      const event_details = NotificationEventDetails[notification.event_name];
      let route: string = event_details.route;
      if (event_details.navigation) route += `${notification.event_id}`;
      await this.$router.push(route);
      await this.dismiss_notification(notification);
      this.toggle_notification_overlay();
    },
    display_route_name(): string {
      const route_name = clean_string(this.$route.name ?? "");
      const module = clean_string(this.module);
      if (module === "candidate") {
        if (route_name === "candidate-dashboard") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/dashboard.svg");
          return this.$t("nav-sidebar.dashboard").toString();
        } else if (route_name === "candidate-jobs") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return this.$t("nav-sidebar.matching-job").toString();
        } else if (route_name === "candidate-courses") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/courses.svg");
          return this.$t("nav-sidebar.courses").toString();
        } else if (
          route_name === "view-profile" ||
          route_name === "personal-profile"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return this.$t("nav-sidebar.profile").toString();
        } else if (
          route_name === "candidate-matching-job" ||
          route_name === "candidate-upskill-career"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return this.$t("nav-sidebar.jobs").toString();
        } else if (route_name === "accounts-setting") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return this.$t("nav-sidebar.settings").toString();
        } else if (route_name === "candidate-error") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return this.$t("nav-sidebar.error").toString();
        } else if (
          route_name === "candidates-interviews" ||
          route_name === "interview-start"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.interviews").toString();
        } else if (route_name === "profile-fetching") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.fetching").toString();
        } else if (
          route_name === "candidate-assessment" ||
          route_name === "inception-anotation-assessment" ||
          route_name === "inception-english-assessment" ||
          route_name === "english-assessment" ||
          route_name === "assessment-report" ||
          route_name === "assessment-completed" ||
          route_name === "annotation-assessment"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.assessment").toString();
        } else {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return this.$t("nav-sidebar.not-found").toString();
        }
      } else if (module === "recruiter" || module === "recruiter_admin") {
        if (route_name === "recruiter-dashboard") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/dashboard.svg");
          return this.$t("nav-sidebar.dashboard").toString();
        }
        // else if (
        //   route_name === "recruiter-jobs" ||
        //   route_name === "recruiter-job-listing" ||
        //   route_name === "recruiter-post-job" ||
        //   route_name === "recruiter-search-standardised-profile" ||
        //   route_name === "view-job-details" ||
        //   route_name === "recruiter-view-job" ||
        //   route_name === "recruiter-edit-job" ||
        //   route_name === "view-standardised-job" ||
        //   route_name === "edit-standardised-job"
        // ) {
        //   this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
        //   return this.$t("nav-sidebar.jobs").toString();
        // }
        else if (
          route_name === "recruiter-candidates" ||
          route_name === "recruiter-view-candidates"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return this.$t("nav-sidebar.candidates").toString();
        } else if (route_name === "candidate-profile") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return this.$t("nav-sidebar.matching-candidates").toString();
        } else if (
          route_name === "recruiter-employees" ||
          route_name === "recruiter-view-employees"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/employees.svg");
          return this.$t("nav-sidebar.employees").toString();
        }
        // else if (route_name === "skills-analytics") {
        //   return "Skills Analytics";
        // }
        else if (
          route_name === "accounts-setting" ||
          route_name === "assessments-setting"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return this.$t("nav-sidebar.settings").toString();
        } else if (route_name === "view-profile") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return this.$t("nav-sidebar.profile").toString();
        } else if (route_name === "recruiter-interviews") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.interviews").toString();
        } else if (
          route_name === "assessment-listing" ||
          route_name === "assessment-report-recruiter"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.assessment").toString();
        } else if (
          route_name === "english-assessment-report" ||
          route_name === "english-assessment" ||
          route_name === "annotation-assesssment-report" ||
          route_name === "annotation-assessment" ||
          route_name === "overall-assessment-report"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return "Assessment";
        } else if (
          route_name === "eng-assessment-report" ||
          route_name === "annotation-assessment-report"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return "Assessment Report ";
        } else if (
          route_name === "assessment-report" // todo [later discuss with faiz bhai]
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/teacher.svg");
          return this.$t("nav-sidebar.report-details").toString();
        } else if (
          route_name === "admin" ||
          route_name === "all-candidates" ||
          route_name === "view-candidate"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/approvals.svg");
          return this.$t("nav-sidebar.approvals").toString();
        } else {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return this.$t("nav-sidebar.not-found").toString();
        }
      } else {
        this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
        return this.$t("nav-sidebar.not-found").toString();
      }
    },
    getTitle(): string {
      return this.$t("shared.not-found").toString();
    },
    /**
     * Function to set sidebar links array
     */
    set_sidebar() {
      this.sidebar_links = [
        {
          name: this.$t("nav-sidebar.dashboard"),
          link: "/candidate/dashboard",
          icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
          module: ["candidate"],
          children: []
        },
        // {
        //   name: this.$t("nav-sidebar.jobs"),
        //   link: "/candidate/jobs",
        //   icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        //   module: ["candidate"],
        //   children: []
        // },
        // {
        //   name: this.$t("nav-sidebar.interviews"),
        //   link: "/candidate/interviews",
        //   icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        //   module: ["candidate"],
        //   children: []
        // },
        // {
        //   name: this.$t("nav-sidebar.assessment"),
        //   link: "/candidate/assessments",
        //   icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        //   module: ["candidate"],
        //   children: []
        // },
        {
          name: this.$t("nav-sidebar.assessment"),
          link: "/candidate/assessments",
          icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
          module: ["candidate"],
          children: [
            {
              name: this.$t("nav-sidebar.candidate-assessment-english"),
              link: "/candidate/assessments/english",
              module: ["candidate"],
              children: []
            },
            {
              name: this.$t("nav-sidebar.candidate-assessment-annotation"),
              module: ["candidate"],
              link: "/candidate/assessments/annotation",
              children: []
            }
          ]
        },
        {
          name: this.$t("nav-sidebar.settings"),
          link: "/settings/account",
          icon: require("@/assets/icons/nav_sidebar/settings.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
          module: ["candidate"],
          children: [
            {
              name: this.$t("nav-sidebar.account"),
              link: "/settings/account",
              icon: require("@/assets/icons/nav_sidebar/settings.svg"),
              active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
              module: ["candidate"],
              children: []
            }
          ]
        },
        {
          name: this.$t("nav-sidebar.dashboard"),
          link: "/recruiter/dashboard",
          icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
          module: ["recruiter", "recruiter_admin"],
          children: []
        },
        // {
        //   name: this.$t("nav-sidebar.jobs"),
        //   icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
        //   link: "/recruiter/jobs",
        //   module: ["recruiter", "recruiter_admin"],
        //   children: [
        //     {
        //       name: this.$t("nav-sidebar.job-listing"),
        //       link: "/recruiter/jobs",
        //       module: ["recruiter", "recruiter_admin"],
        //       children: []
        //     },
        //     {
        //       name: this.$t("nav-sidebar.post-job"),
        //       module: ["recruiter", "recruiter_admin"],
        //       link: "/recruiter/jobs/post-job",
        //       children: []
        //     }
        //   ]
        // },
        // {
        //   name: this.$t("nav-sidebar.interviews"),
        //   link: "/recruiter/interviews",
        //   icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        //   module: ["recruiter", "recruiter_admin"],
        //   children: []
        // },
        // {
        //   name: this.$t("nav-sidebar.assessment"),
        //   link: "/recruiter/assessments",
        //   icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        //   module: ["recruiter", "recruiter_admin"],
        //   children: []
        // },
        {
          name: this.$t("nav-sidebar.assessment"),
          link: "/recruiter/assessments",
          icon: require("@/assets/icons/nav_sidebar/teacher.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
          module: ["recruiter", "recruiter_admin"],
          children: [
            // English assessment V1
            {
              name: this.$t("nav-sidebar.assessment-english"),
              link: "/recruiter/assessments/english/v1",
              module: ["recruiter", "recruiter_admin"],
              children: []
            },
            // English assessment V2
            {
              name: this.$t("nav-sidebar.assessment-english-v2"),
              link: "/recruiter/assessments/english/v2",
              module: ["recruiter", "recruiter_admin"],
              children: []
            },
            // Annotation assessment V1
            {
              name: this.$t("nav-sidebar.assessment-annotation"),
              module: ["recruiter", "recruiter_admin"],
              link: "/recruiter/assessments/annotation/v1",
              children: []
            },
            // Annotation assessment V2
            {
              name: this.$t("nav-sidebar.assessment-annotation-v2"),
              module: ["recruiter", "recruiter_admin"],
              link: "/recruiter/assessments/annotation/v2",
              children: []
            },
            {
              name: "Summary Results",
              module: ["recruiter", "recruiter_admin"],
              link: "/recruiter/assessments/overall-result",
              children: []
            }
          ]
        },
        {
          name: this.$t("nav-sidebar.candidates"),
          link: "/recruiter/candidates",
          icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/candidates.svg"),
          module: ["recruiter", "recruiter_admin"],
          children: []
        },
        // {
        //   name: this.$t("nav-sidebar.skill-analytics"),
        //   link: "/recruiter/skills-analytics",
        //   icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/candidates.svg"),
        //   module: ["recruiter", "recruiter_admin"],
        //   children: []
        // },
        {
          name: this.$t("nav-sidebar.approvals"),
          link: "/recruiter/admin/candidates",
          icon: require("@/assets/icons/nav_sidebar/approvals.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/approvals.svg"),
          module: ["recruiter_admin"],
          children: []
        },
        {
          name: this.$t("nav-sidebar.employees"),
          link: "/recruiter/employees",
          icon: require("@/assets/icons/nav_sidebar/employees.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/employees.svg"),
          module: ["recruiter"],
          children: []
        },
        {
          name: this.$t("nav-sidebar.settings"),
          link: "/settings/account",
          icon: require("@/assets/icons/nav_sidebar/settings.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
          module: ["recruiter", "recruiter_admin"],
          children: [
            {
              name: this.$t("nav-sidebar.account"),
              link: "/settings/account",
              module: ["recruiter", "recruiter_admin"],
              children: []
            },
            {
              name: this.$t("nav-sidebar.assessment"),
              link: "/settings/assessments",
              module: ["recruiter", "recruiter_admin"],
              children: []
            }
          ]
        }
      ];
    },
    toggle_notification_overlay() {
      this.notification_overlay = !this.notification_overlay;
    }
  }
});
