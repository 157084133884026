export interface NotificationsResponse {
  created_at: string;
  created_by: number | null;
  event_id: number;
  event_name: NotificationEvents;
  id: number;
  is_read: boolean;
  message: string;
  notification_type: string;
  reciever_id: number;
  sender_id: number;
  updated_at: number | null;
}

export enum NotificationEvents {
  INTERVIEW_INVITE_SENT = "INTERVIEW_INVITE_SENT",
  INTERVIEW_COMPLETED = "INTERVIEW_COMPLETED"
}

export enum NotificationsEventRoutes {
  INTERVIEW_INVITE_SENT = "/candidate/interviews/start/",
  INTERVIEW_COMPLETED = "/recruiter/interviews/"
}

export const NotificationEventDetails = {
  [NotificationEvents.INTERVIEW_INVITE_SENT]: {
    navigation: true,
    route: NotificationsEventRoutes.INTERVIEW_INVITE_SENT
  },
  [NotificationEvents.INTERVIEW_COMPLETED]: {
    navigation: false,
    route: NotificationsEventRoutes.INTERVIEW_COMPLETED
  }
};
